import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';

import { Link as Anchor, useLocation } from "react-router-dom";
import logo from '../../images/icon.png';
import icon from '../../images/icon.png';
import baseURL from '../url';
import 'swiper/swiper-bundle.css';
import './Navbar.css'
import InputSerach from '../InputSerach/InputSearchs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faEnvelope } from '@fortawesome/free-solid-svg-icons';
export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    useEffect(() => {
        cargarBanners();
    }, []);

    const cargarBanners = () => {
        fetch(`${baseURL}/bannersGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const bannerImages = data.banner.map(banner => banner.imagen);
                setImages(bannerImages);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error)

            });
    };
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    return (
        <header>
            <nav>
                <Anchor to={`/`} className='logo'>
                    <img src={logo} alt="logo" />
                </Anchor>

                <div className='enlaces'>
                    <Anchor to={`/`} className={location.pathname === '/' ? 'active' : ''} >Inicio</Anchor>
                    <Anchor to={`/nosotros`} className={location.pathname === '/nosotros' ? 'active' : ''} >Nosotros</Anchor>
                    <Anchor to={`/contacto`} className={location.pathname === '/contacto' ? 'active' : ''} >Contacto</Anchor>

                </div>

                <div className='deFLexNavs'>
                    <InputSerach />
                    <div className={`nav_toggle  ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>




            </nav>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                className="modalNav"
                overlayClassName="overlay"
            >
                <div className="modalNav-content">
                    {loading ? (
                        <div className='loadingBannerfondo'>

                        </div>

                    ) : (

                        <div className='fondo'>
                            <img src={images[0]} alt={`imagen`} />

                        </div>

                    )}
                    <img src={icon} alt="icon" className='iconImg' />
                    <div className='enlaces2'>
                        <Anchor to={`/`} className={location.pathname === '/' ? 'active2' : ''} onClick={handleCloseModal}>
                            <FontAwesomeIcon icon={faHome} /> Inicio
                        </Anchor>
                        <Anchor to={`/nosotros`} className={location.pathname === '/nosotros' ? 'active2' : ''} onClick={handleCloseModal}>
                            <FontAwesomeIcon icon={faUsers} /> Nosotros
                        </Anchor>
                        <Anchor to={`/contacto`} className={location.pathname === '/contacto' ? 'active2' : ''} onClick={handleCloseModal}>
                            <FontAwesomeIcon icon={faEnvelope} /> Contacto
                        </Anchor>

                    </div>
                </div>
            </Modal>
        </header>
    );
}

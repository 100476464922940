import React, { useState } from 'react';
import './Contact.css';
import logo from '../../images/logo.png'

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes manejar la lógica de envío del formulario
        console.log(formData);
        // Reiniciar el estado del formulario después del envío
        setFormData({
            name: '',
            email: '',
            message: ''

        });
    };

    return (
        <div className='contactContain'>
            <img src={logo} alt="logo" className='logoAbout' />
            <div className='contactForm'>
                <form onSubmit={handleSubmit} className='form'>
                    <label htmlFor="">¿Tienes alguna consulta?</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder='Nombre'
                    />
                    <input
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder='Email'
                        required
                    />

                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder='Consulta'
                        required
                    ></textarea>

                    <button type="submit" className='btn'>Enviar</button>
                </form>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113927.15975796667!2d-65.3049951248154!3d-26.83283503327894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94223792d6c56903%3A0xf88d5b92b5c56527!2sSan%20Miguel%20de%20Tucum%C3%A1n%2C%20Tucum%C3%A1n!5e0!3m2!1ses-419!2sar!4v1711044962332!5m2!1ses-419!2sar" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
    );
}

import React from 'react';
import './About.css';
import logo from '../../images/icon.png';
import { Link as Anchor } from "react-router-dom";
import img1 from '../../images/1.png';
import img2 from '../../images/2.png';
import img3 from '../../images/3.png';
import img4 from '../../images/4.png';
import img5 from '../../images/5.png';
import img6 from '../../images/6.png';
import img7 from '../../images/7.png';
export default function About() {
    const calificaciones = [
        {
            texto: 'Calidad y Garantia',
            imagen: img1,
        },
        {
            texto: 'Compromiso',
            imagen: img2,
        },
        {
            texto: 'Experiencia Profesional',
            imagen: img3,
        },
        {
            texto: '24hs Servicio al Cliente',
            imagen: img4,
        },
        {
            texto: 'Asesoramiento Personalizado',
            imagen: img5,
        },
    ];

    return (
        <div className='abouContain'>
            <div className='aboutText'>
                <img src={logo} alt="logo" className='logoAbout' />
                <div className='deFlexTitle'>
                    <img src={img6} alt="imagen" />
                    <h1>Quiénes somos</h1>
                    <img src={img7} alt="imagen" />
                </div>
                <p>Somos una empresa que busca brindarte la mejor atención y asesoramiento a la hora de buscar tu producto.</p>
            </div>

            <div className='aboutText2'>
                <h2>¿QUÉ NOS CALIFICA?</h2>
                <div className='calificacionesContainer'>
                    {calificaciones.map((item, index) => (
                        <div key={index} className='calificacionItem'>
                            <img src={item.imagen} alt={item.texto} />
                            <p>{item.texto}</p>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
}
